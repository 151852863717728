import {Box, Grid, Typography} from '@material-ui/core'
import {Loader} from 'lib/ui/Loader'
import Center from 'lib/ui/layout/Center'
import {ceil} from 'lodash'
import React from 'react'

export type BroadcastEmailStatistic = {
  sent: number
  opened: number
  clicked: number
  complaints: number
  bounced: number
}

export function StatisticsForEmailBroadcast(props: {
  statistics: BroadcastEmailStatistic | null
}) {
  const statistics = props.statistics

  if (!statistics) {
    return (
      <Box p={4}>
        <Center>
          <Loader />
        </Center>
      </Box>
    )
  }

  const getPercents = (number: number) => {
    return ceil((number / statistics.sent) * 100) || 0
  }

  return (
    <Box minWidth={120} maxWidth={300} p={2}>
      <Grid container>
        <Grid item xs={8}>
          Total Sent:
        </Grid>
        <Grid item xs={4}>
          <Typography align="right" aria-label={`totalsend:${statistics.sent}`}>
            {statistics.sent}
          </Typography>
        </Grid>
        <Grid item xs={8}>
          Total Opened:
        </Grid>
        <Grid item xs={4}>
          <Typography
            align="right"
            aria-label={`totalopened:${statistics.opened}`}
          >
            {statistics.opened} ({getPercents(statistics.opened)}%)
          </Typography>
        </Grid>

        <Grid item xs={8}>
          Total Clicked:
        </Grid>
        <Grid item xs={4}>
          <Typography
            align="right"
            aria-label={`totalclicked:${statistics.clicked}`}
          >
            {statistics.clicked} ({getPercents(statistics.clicked)}%)
          </Typography>
        </Grid>

        <Grid item xs={8}>
          Total Complaints:
        </Grid>
        <Grid item xs={4}>
          <Typography
            align="right"
            aria-label={`totalcomplaints:${statistics.complaints}`}
          >
            {statistics.complaints} ({getPercents(statistics.complaints)}%)
          </Typography>
        </Grid>

        <Grid item xs={8}>
          Total Bounced:
        </Grid>
        <Grid item xs={4}>
          <Typography
            align="right"
            aria-label={`totalbounced:${statistics.bounced}`}
          >
            {statistics.bounced} ({getPercents(statistics.bounced)}%)
          </Typography>
        </Grid>
      </Grid>
    </Box>
  )
}
